import { render, staticRenderFns } from "./Shop.vue?vue&type=template&id=62858b7e&scoped=true&"
import script from "./Shop.vue?vue&type=script&lang=js&"
export * from "./Shop.vue?vue&type=script&lang=js&"
import style0 from "./Shop.vue?vue&type=style&index=0&id=62858b7e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62858b7e",
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BAlert} from 'bootstrap-vue'
    installComponents(component, {BAlert})
    

export default component.exports