<template>
  <div>
    <b-button
      v-b-toggle.collapse-categories
      class="pl-0 ml-0 side-cat_title"
      variant="link"
      >Categorieën</b-button
    >
    <b-collapse id="collapse-categories" visible class="mt-2">
      <div>
        <ul class="side-cats">
          <li v-for="category in categories" :key="category.CategoryID">
            <router-link
              :to="`/shop/${category.Slug}`"
              :class="{
                'text-primary': currentCategory === category.Slug
              }"
              >{{ category.Description }}</router-link
            >
          </li>
        </ul>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      required: true,
      type: Array,
      default: function() {
        return []
      }
    }
  },
  computed: {
    currentCategory: function() {
      if (this.$route.params.categoryID) return this.$route.params.categoryID
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.side-cat_title {
  font-weight: 900;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0px;
  width: 100%;
  position: relative;
  /* background: #f3f3f3; */
  padding: 13px;
  padding-left: 0;
  color: #333333;
  font-family: 'Open Sans', Helvetica, sans-serif;
  text-decoration: none;
  text-align: left;
}
ul.side-cats {
  font-weight: bold;
  list-style: none;
  li > a {
    display: block;
    padding: 0;
    color: #333333 !important;
    min-height: 50px;
    line-height: 50px;
    padding-right: 55px;
    border-bottom: 1px solid #ececec;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
  }
  li > a.text-primary {
    color: #a73b35 !important;
  }
}
</style>
