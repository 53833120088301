const { getCategory } = require('./CategoryService')
import store from '@/state/store'

async function generateBreadCrumbs({ categoryID, item = false } = {}) {
  let breadCrumbs = []
  breadCrumbs.push({ page: 'index', title: 'Uitleendienst', path: '/' })

  if (categoryID) {
    const categoryBreadCrumbs = await categoryToBreadCrumbs({
      categoryID,
      item
    })
    breadCrumbs = breadCrumbs.concat(categoryBreadCrumbs)
  }

  return breadCrumbs
}

async function categoryToBreadCrumbs({ categoryID, item = false } = {}) {
  let breadCrumbs = []

  const categoryIDIsNum = /^\d+$/.test(categoryID)

  const currentCategory =
    store.getters['categories/getCategory'](categoryID) ??
    (await getCategory({
      categoryID,
      slug: !categoryIDIsNum
    }))

  if (currentCategory.ParentCategoryID) {
    const parentCategory =
      store.getters['categories/getCategory'](
        currentCategory.ParentCategoryID
      ) ??
      (await getCategory({
        categoryID: currentCategory.ParentCategoryID,
        slug: false
      }))
    if (item) {
      breadCrumbs.push({
        title: capitalizeFirstLetter(currentCategory.Description),
        path: `/shop/${parentCategory.Slug}/${currentCategory.Slug}`
      })
    }

    breadCrumbs.push({
      title: parentCategory.Description,
      path: `/shop/${parentCategory.Slug}`
    })
  } else {
    breadCrumbs.push({
      page: 'categories',
      title: capitalizeFirstLetter(currentCategory.Description),
      params: { categoryID: currentCategory.CategoryID },
      path: `/shop/${currentCategory.Slug}`
    })
  }

  return breadCrumbs.reverse()
}

function capitalizeFirstLetter(string) {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1)
  return null
}

module.exports = { generateBreadCrumbs }
