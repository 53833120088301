<template>
  <div class="container">
    <b-alert v-if="notificationMessage" show variant="danger">
      {{ notificationMessage }}
    </b-alert>
    <Items :search-term="searchTerm" />
  </div>
</template>

<script>
// @ is an alias to /src
import Items from '@/components/items/Index'

export default {
  name: 'Shop',
  components: {
    Items
  },
  computed: {
    searchTerm: function() {
      if (this.$route.params.searchTerm) {
        return this.$route.params.searchTerm
      } else {
        return null
      }
    },
    notificationMessage: function() {
      if (this.$route.query.loggedOut) return 'U bent succesvol uitgelogd'
      if (this.$route.query.loggedIn) return 'U bent succesvol ingelogd'
      if (this.$route.query.sessionExpired)
        return 'Je bent uitgelogd omdat je ingelogd bent op een andere locatie of omdat je sessie verlopen is.'
      return null
    }
  }
}
</script>
<style scoped lang="scss">
.container {
  background-color: rgba(247, 247, 247, 1);
}
</style>
