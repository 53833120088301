<template>
  <div class="card card-product">
    <router-link :to="{ path: routePath }">
      <div
        v-if="imageUrl"
        class="card-img-top p-2 mt-2"
        @click="navigateToItem"
      >
        <clazy-load v-if="imageUrl" :src="imageUrl">
          <!-- The image slot renders after the image loads. -->
          <transition name="fade">
            <img
              :alt="image.DefaultAltTag"
              crossorigin="anonymous"
              class="rounded"
              :src="imageUrl"
            />
          </transition>
          <!-- The placeholder slot displays while the image is loading. -->
          <transition slot="placeholder" name="fade">
            <!-- You can put any component you want in here. -->
            <img
              v-if="imageUrl"
              alt="Placeholder"
              crossorigin="anonymous"
              class="rounded"
              src="/img/placeholder.jpg"
            />
          </transition>
        </clazy-load>

        <img v-else class="d-block mx-auto" src="/img/itemFallback.png" />
      </div>

      <div
        v-if="item.Type === 'Composition' && !imageUrl"
        class="card-img-top p-2 mt-2"
      >
        <ImageGrid :images="compostionImages" />
      </div>
    </router-link>
    <div class="card-body text-left d-flex flex-column">
      <a v-if="item.Brand" class="product-brand" href="#">{{ item.Brand }}</a>
      <h3 class="product-title font-size-sm">
        <a :href="routePath">
          <strong class="text-primary"></strong>
          {{ item.Description }}
        </a>
      </h3>
      <div class="content" v-html="note" />
      <router-link
        v-if="note.length > 100"
        class="text-primary float-right"
        :to="{ path: routePath }"
      ></router-link>

      <div class="mt-auto">
        <div class="product-price">
          <p v-if="price">
            <strong class>{{ price }} euro huurprijs</strong>
          </p>
        </div>

        <a
          :href="routePath"
          class="btn btn-primary mb-2"
          data-toggle="toast"
          data-target="#cart-toast"
        >
          <font-awesome-icon icon="info-circle" />
        </a>
        <item-button-add-to-cart
          v-if="user.UserName && cartCount > 0"
          class="ml-3"
          :icon="true"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageGrid from '@/components/items/ImageGrid'
import ItemButtonAddToCart from './ItemButtonAddToCart'
// @ is an alias to /src

export default {
  name: 'Item',
  components: {
    ImageGrid,
    'item-button-add-to-cart': ItemButtonAddToCart
  },
  props: {
    item: {
      type: Object,
      default: function() {
        return {}
      }
    },
    suggestedItem: {
      type: Boolean,
      required: false,
      default: function() {
        return false
      }
    }
  },
  computed: {
    cartCount: function() {
      return this.$store.state.cartCount
    },
    itemPrefixUrlPath: function() {
      if (this.$route.params.categoryID) return this.$route.path
      if (this.$route.path.includes('zoeken')) return '/shop'
      return '/shop/geluidsmateriaal'
    },
    user: function() {
      return this.$store.getters.user
    },
    image: function() {
      if (this.item.Images && this.item.Images.length >= 1) {
        if (this.item.Images[0].ImageUrl) {
          return this.item.Images[0].ImageUrl
        }
        return this.item.Images[0]
      } else {
        return null
      }
    },
    imageUrl: function() {
      if (this.image) {
        return `${process.env.VUE_APP_URL}${this.image}&Format=Standard`
      } else {
        return null
      }
    },
    compostionImages: function() {
      if (this.item.Type === 'Composition') {
        let images = []

        // get first image string from all items in this.item.Items
        for (let item of this.item.Items) {
          if (item.Images && item.Images.length > 0) {
            images.push(
              `${process.env.VUE_APP_URL}${item.Images[0]}&Format=Standard`
            )
          }
        }

        if (
          this.item.CategoryIDs.includes('32') ||
          this.item.CategoryIDs.includes('35')
        ) {
          return images.slice(0, 4)
        }

        return images.slice(0, 1)
      }
      return null
    },
    routePath: function() {
      if (this.suggestedItem) return `/shop/artikel/${this.item.Slug}`
      return `${this.itemPrefixUrlPath}/artikel/${this.item.Slug}`
    },

    note: function() {
      if (this.item.MediumDescription === null) return ''
      if (this.item.MediumDescription.length > 100) {
        return `${this.item.MediumDescription.replace('<br>', '')
          .replace('<br />\n', '')
          .replace('<br />\n', '')
          .replace('<p>', '')
          .replace('</p>', '')
          .substring(0, 100)}... <a class="text-primary" href="${
          this.routePath
        }">Lees verder</a>`
      }
      return this.item.MediumDescription.replace('<br>', '')
        .replace('<p>', '')
        .replace('</p>', '')
    },
    price() {
      let price = this.item.CustomFields?.CUST_WeekPrice
      // format price with 2 decimals
      if (price) {
        price = price.toFixed(2)
        // replace '.' with ','
        price = price.replace('.', ',')
        return price
      }

      return null
    }
  },
  methods: {
    randomNumber() {
      return Math.floor(Math.random() * (11 - 99 + 1) + 99)
    },
    navigateToItem() {
      window.location = this.routePath
    }
  }
}
</script>
<style scoped lang="scss">
img {
  cursor: pointer;
}
.card {
  background-color: rgba(0, 0, 0, 0);
  img {
    max-width: 100%;
  }
  br {
    display: none;
  }
  height: 100% !important;
  .buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.card:after {
  margin-bottom: 10px;
}
.card-product {
  border: none;
}
.product-price {
  margin-top: -20px;
}
.content {
  min-height: 105px;
}

.text-accent {
  color: #4e54c8 !important;
}

.product-title {
  min-height: 50px;
}

h3 {
  font-size: 1.3rem !important;
  a {
    color: #373f50 !important;
  }
  strong {
    color: #373f50 !important;
  }
}
.product-brand {
  transition: color 0.25s ease-in-out;
  color: #7d879c;
  text-decoration: none !important;
  font-size: 0.75rem !important;
}

.card-img-top {
  min-height: 172px;
}
.content {
  margin-bottom: 45px;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  -webkit-transition: opacity 3s ease-in-out;
  -moz-transition: opacity 3s ease-in-out;
  -ms-transition: opacity 3s ease-in-out;
  -o-transition: opacity 3s ease-in-out;
}
.fade-in {
  opacity: 1;
}
</style>
