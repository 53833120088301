const { api } = require('./Api')
const { checkResponse } = require('../controllers/errorHandler')

import store from '../state/store'

async function getItems({
  size = 12,
  index = 1,
  categoryID = null,
  search = null,

  sortReverse = null
} = {}) {
  let categoryIDs = categoryID ? `${categoryID}` : null

  const result = api.get('/v2/shop/items', {
    params: {
      size,
      index,
      search,
      CategoryIDs: categoryIDs,
      sortReverse
    },
    headers: { authorization: store.getters.token }
  })

  if ((await checkResponse(result)) !== null) return

  return result
}
async function getSuggestedItems({ itemID }) {
  const result = await api.get(`/v1/custitems/${itemID}/itemSuggestions`, {
    params: {
      allItemData: true
    }
  })

  return result.data.Collection
}
async function getItem({ itemID } = {}) {
  const result = await api.get(`v2/shop/items/${itemID}/`)

  if ((await checkResponse(result)) !== null) return

  return result.data
}

async function getItemStock({ itemID, startDate, endDate, warehouse } = {}) {
  const result = await api.get(`v2/shop/items/${itemID}/stock`, {
    params: {
      DateTimeBusinessStart: startDate,
      DateTimeBusinessEnd: endDate,
      DateTimeExpectedStart: startDate,
      DateTimeExpectedEnd: endDate,
      contactID: store.getters.contactID,
      warehouse: warehouse
    }
  })

  if ((await checkResponse(result)) !== null) return

  return result.data
}

async function getItemPrice({ itemId, startDate, endDate, warehouse } = {}) {
  const result = await api.get(`/v2/shop/items/${itemId}/price`, {
    params: {
      DateTimeBusinessStart: startDate,
      DateTimeBusinessEnd: endDate,
      contactID: store.getters.contactID,
      warehouse: warehouse ?? 'Brugge'
    },

    headers: { authorization: store.getters.token }
  })

  if ((await checkResponse(result)) !== null) return

  return result.data
}

module.exports = {
  getItems,
  getItem,
  getSuggestedItems,
  getItemStock,
  getItemPrice
}
