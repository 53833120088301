<template>
  <div class="card card-product mt-1">
    <div class="card-body">
      <b-row>
        <b-col xs="12" lg="3">
          <router-link
            :to="{
              path: `${itemPrefixUrlPath}/artikel/${item.Slug}`
            }"
          >
            <div v-if="imageUrl" @click="navigateToItem">
              <clazy-load v-if="imageUrl" :src="imageUrl">
                <!-- The image slot renders after the image loads. -->
                <transition name="fade">
                  <img
                    :alt="image.DefaultAltTag"
                    crossorigin="anonymous"
                    class="rounded"
                    :src="imageUrl"
                  />
                </transition>
                <!-- The placeholder slot displays while the image is loading. -->
                <transition slot="placeholder" name="fade">
                  <!-- You can put any component you want in here. -->
                  <img
                    v-if="imageUrl"
                    alt="Placeholder"
                    crossorigin="anonymous"
                    class="rounded"
                    src="/img/placeholder.jpg"
                  />
                </transition>
              </clazy-load>
              <div v-if="imageUrls > 0" @click="navigateToItem">
                <b-row>
                  <b-col v-for="imageUrl in imageUrls" :key="imageUrl" cols="6">
                    <clazy-load :src="imageUrl">
                      <!-- The image slot renders after the image loads. -->
                      <transition name="fade">
                        <img
                          :alt="item.Description"
                          crossorigin="anonymous"
                          class="rounded"
                          :src="`image`"
                        />
                      </transition>
                      <!-- The placeholder slot displays while the image is loading. -->
                      <transition slot="placeholder" name="fade">
                        <!-- You can put any component you want in here. -->
                        <img
                          alt="Placeholder"
                          crossorigin="anonymous"
                          class="rounded"
                          src="/img/placeholder.jpg"
                        />
                      </transition>
                    </clazy-load>
                  </b-col>
                </b-row>
              </div>
              <img
                v-if="!imageUrl && !imageUrls"
                class="d-block mx-auto"
                src="/img/itemFallback.png"
              />
            </div>
            <div
              v-if="item.Type === 'Composition' && !imageUrl"
              class="card-img-top p-2 mt-2"
            >
              <ImageGrid
                v-if="compostionImages.length"
                :images="compostionImages"
              />
            </div>
          </router-link>
        </b-col>
        <b-col xs="12" lg="9">
          <a v-if="item.Brand" class="product-brand" href="#">{{
            item.Brand
          }}</a>
          <router-link
            :to="{
              path: `${itemPrefixUrlPath}/artikel/${item.Slug}`
            }"
          >
            <h3>{{ item.Description }}</h3>
          </router-link>
          <div class="mb-2" v-html="note" />

          <div v-if="price" class="d-flex justify-content-between">
            <div class="product-price">
              <p>
                <strong>{{ price }} euro huurprijs</strong>
              </p>
            </div>
          </div>
          <router-link
            class="btn btn-primary mb-2 mr-2"
            data-toggle="toast"
            data-target="#cart-toast"
            :to="{
              path: `${itemPrefixUrlPath}/artikel/${item.Slug}`
            }"
          >
            <i class="czi-cart font-size-sm mr-1"></i>Bekijk details
          </router-link>
          <item-button-add-to-cart
            v-if="user.UserName && cartCount > 0"
            :item="item"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ImageGrid from '@/components/items/ImageGrid'
import ItemButtonAddToCart from './ItemButtonAddToCart'
export default {
  name: 'Item',
  components: {
    ImageGrid,
    'item-button-add-to-cart': ItemButtonAddToCart
  },
  props: {
    item: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  computed: {
    cartCount: function() {
      return this.$store.state.cartCount
    },
    itemPrefixUrlPath: function() {
      if (this.$route.params.categoryID) return this.$route.path
      if (this.$route.path.includes('zoeken')) return '/shop'
      return '/shop/geluidsmateriaal'
    },
    image: function() {
      if (this.item.Images && this.item.Images?.length >= 1) {
        return `${this.item.Images[0]}&format=Standard`
      } else {
        return null
      }
    },
    user: function() {
      return this.$store.getters.user
    },
    routePath: function() {
      if (this.suggestedItem) return `/shop/artikel/${this.item.Slug}`
      return `${this.itemPrefixUrlPath}/artikel/${this.item.Slug}`
    },
    imageUrl: function() {
      if (this.item.Images?.length > 0) {
        return `${process.env.VUE_APP_URL}${this.item.Images[0]}&Format=Standard`
      } else {
        return null
      }
    },
    compostionImages: function() {
      if (this.item.Type === 'Composition') {
        let images = []

        // get first image string from all items in this.item.Items
        for (let item of this.item.Items) {
          if (item.Images && item.Images.length > 0) {
            images.push(
              `${process.env.VUE_APP_URL}${item.Images[0]}&Format=Standard`
            )
          }
        }

        if (
          this.item.CategoryIDs.includes('32') ||
          this.item.CategoryIDs.includes('35')
        ) {
          return images.slice(0, 4)
        }

        return images.slice(0, 1)
      }
      return null
    },
    imageUrls: function() {
      if (this.item.Items) {
        return this.item.Items.map(a => a.ImageUrl)
      }
      return ''
    },
    note: function() {
      if (this.item.MediumDescription.length > 300) {
        return `${this.item.MediumDescription.replace('<br>', '')
          .replace('<br />\n', '')
          .replace('<br />\n', '')
          .replace('<p>', '')
          .replace('</p>', '')
          .substring(0, 300)}... <a class="text-primary" href="${
          this.itemPrefixUrlPath
        }/artikel/${this.item.Slug}">Lees verder</a>`
      }
      return this.item.MediumDescription.replace('<br>', '')
        .replace('<p>', '')
        .replace('</p>', '')
    },
    price: function() {
      return this.item.CustomFields?.CUST_WeekPrice
    }
  },
  methods: {
    randomNumber: function() {
      return Math.floor(Math.random() * (11 - 99 + 1) + 99)
    },
    navigateToItem: function() {
      window.location = this.routePath
    }
  }
}
</script>
<style scoped lang="scss">
img {
  cursor: pointer;
}
.card {
  background-color: rgba(0, 0, 0, 0);
  br {
    display: none;
  }
  img {
    width: 100%;
  }
}

.card-product {
  border: none;
  a {
    text-decoration: none;
  }
}

.product-title {
  min-height: 50px;
}

h3 {
  a {
    color: #373f50 !important;
  }
  strong {
    color: #373f50 !important;
  }
  text-decoration: none;
}
.product-brand {
  transition: color 0.25s ease-in-out;
  color: #7d879c;
  text-decoration: none !important;
  font-size: 0.75rem !important;
}

.card-img-top {
  min-height: 172px;
}
</style>
