<template>
  <div class="compositionGrid">
    <!-- START 1 image -->
    <b-row v-if="images.length === 1">
      <b-col v-for="imageUrl in images" :key="imageUrl" cols="12">
        <clazy-load :src="imageUrl">
          <!-- The image slot renders after the image loads. -->
          <transition name="fade">
            <b-img
              crossorigin="anonymous"
              class="rounded"
              fluid
              :src="imageUrl"
            />
          </transition>
          <!-- The placeholder slot displays while the image is loading. -->
          <transition slot="placeholder" name="fade">
            <!-- You can put any component you want in here. -->
            <b-img
              alt="Placeholder"
              crossorigin="anonymous"
              class="rounded"
              fluid
              src="/img/placeholder.jpg"
            />
          </transition>
        </clazy-load>
      </b-col>
    </b-row>
    <!-- END 1 image -->
    <!-- START 2 images -->
    <b-row v-if="images.length === 2">
      <b-col
        v-for="imageUrl in images"
        :key="imageUrl"
        class="2-images"
        cols="6"
      >
        <clazy-load :src="imageUrl">
          <!-- The image slot renders after the image loads. -->
          <transition name="fade">
            <b-img
              crossorigin="anonymous"
              class="rounded"
              fluid
              :src="imageUrl"
            />
          </transition>
          <!-- The placeholder slot displays while the image is loading. -->
          <transition slot="placeholder" name="fade">
            <!-- You can put any component you want in here. -->
            <b-img
              alt="Placeholder"
              crossorigin="anonymous"
              class="rounded"
              fluid
              src="/img/placeholder.jpg"
            />
          </transition>
        </clazy-load>
      </b-col>
    </b-row>
    <!-- END 2 images -->
    <!-- START 3 images -->
    <b-row v-if="images.length === 3" class="images-3">
      <b-col v-for="imageUrl in images" :key="imageUrl" class="mt-4" cols="6">
        <clazy-load :src="imageUrl">
          <!-- The image slot renders after the image loads. -->
          <transition name="fade">
            <b-img
              crossorigin="anonymous"
              class="rounded"
              fluid
              :src="imageUrl"
            />
          </transition>
          <!-- The placeholder slot displays while the image is loading. -->
          <transition slot="placeholder" name="fade">
            <!-- You can put any component you want in here. -->
            <b-img
              alt="Placeholder"
              crossorigin="anonymous"
              class="rounded"
              fluid
              src="/img/placeholder.jpg"
            />
          </transition>
        </clazy-load>
      </b-col>
    </b-row>
    <!-- END 3 images -->
    <!-- START 4 images or more -->
    <b-row v-if="images.length >= 4" class="images-4">
      <b-col v-for="imageUrl in images" :key="imageUrl" class="mt-4" cols="6">
        <clazy-load :src="imageUrl">
          <!-- The image slot renders after the image loads. -->
          <transition name="fade">
            <b-img
              crossorigin="anonymous"
              class="rounded"
              fluid
              :src="imageUrl"
            />
          </transition>
          <!-- The placeholder slot displays while the image is loading. -->
          <transition slot="placeholder" name="fade">
            <!-- You can put any component you want in here. -->
            <b-img
              alt="Placeholder"
              crossorigin="anonymous"
              class="rounded"
              fluid
              src="/img/placeholder.jpg"
            />
          </transition>
        </clazy-load>
      </b-col>
    </b-row>
    <!-- END 4 images or more -->
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      default: function() {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.compositionGrid {
  min-height: 239px;
}

.images-3,
.images-4 {
  margin-top: -23px;
}
</style>
