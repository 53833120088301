<template>
  <content>
    <b-row>
      <b-col cols="12">
        <bread-crumbs
          v-if="currentCategory !== '' && !searchTerm"
          :currentpage="`${currentCategory.Description}`"
        />
        <bread-crumbs
          v-if="searchTerm"
          :currentpage="`Zoeken naar: ${searchTerm}`"
        />
      </b-col>

      <b-col md="4" lg="3">
        <div v-if="false">
          <label>Sorteren op:</label>
          <b-form-select
            id="sortOption"
            v-model="sortOption"
            :options="sortOptions"
            size="sm"
          ></b-form-select>
          <hr />
        </div>
        <sidebar :categories="categories" />
      </b-col>
      <b-col>
        <b-row align-h="end">
          <b-col>
            <h1 v-if="!searchTerm">{{ currentCategory.Description }}</h1>
            <h1 v-else>Zoeken naar: {{ searchTerm }}</h1>
          </b-col>

          <b-col lg="3" md="0" class="d-none d-lg-block text-right mt-2">
            <b-button-group class="">
              <b-button
                :class="{ disabled: !gridListing }"
                @click="toggleGridListing"
              >
                <font-awesome-icon :icon="['fas', 'th-list']" />
              </b-button>
              <b-button
                :class="{ disabled: gridListing }"
                @click="toggleGridListing"
              >
                <font-awesome-icon :icon="['fas', 'th']" />
              </b-button>
            </b-button-group>
          </b-col>
          <b-col cols="12"> </b-col>
        </b-row>

        <p
          v-if="currentCategory.Description && !searchTerm"
          v-html="currentCategory.Notes"
        />

        <b-row v-if="gridListing" class="row">
          <b-col
            v-for="item in items"
            :key="item.ID"
            xs="12"
            lg="6"
            xl="4"
            class="mt-4"
          >
            <Item :item="item" />
          </b-col>
        </b-row>
        <b-row v-if="!gridListing" class="row">
          <b-col v-for="item in items" :key="item.ID" cols="12">
            <item-horizontal :item="item" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </content>
</template>

<script>
// @ is an alias to /src
import { getItems } from '@/services/ItemService'
import Item from '@/components/items/Item'
import ItemHorizontal from '@/components/items/Item-horizontal'
import SideBar from '@/components/sidebar/sidebar'
import { generateBreadCrumbs } from '@/services/Breadcrumbs'
import { mapGetters } from 'vuex'

export default {
  name: 'Items',
  components: {
    Item,
    ItemHorizontal,
    sidebar: SideBar
  },

  props: {
    searchTerm: {
      type: String,
      required: false,
      default: function() {
        return ''
      }
    }
  },
  events: {
    confirm: function(argument) {
      this.calendar.startDate = argument
    },
    checkOutChanged: function(argument) {
      this.calendar.endDate = argument
    }
  },
  data() {
    return {
      items: [],
      index: 1,
      size: 900,
      total: 1,
      gettingData: false,

      breadCrumbs: [],
      dateSelection: '',
      sortOption: null,
      sortOptions: [
        { value: null, text: 'Standaard' },
        { value: 'az', text: 'A-Z' },
        { value: 'za', text: 'Z-A' },
        { value: 'price-low-high', text: 'Prijs laag hoog' },
        { value: 'price-high-low', text: 'Prijs hoog laag' }
      ]
    }
  },
  metaInfo() {
    return {
      title: `${this.currentCategory.Description}`
    }
  },
  computed: {
    pages: function() {
      return Math.ceil(this.total / this.size)
    },
    ...mapGetters('categories', {
      categories: 'getCategories'
    }),
    currentCategory() {
      return (
        this.$store.getters['categories/getCategory'](this.categoryID) ?? {
          Description: ''
        }
      )
    },
    categoryID: function() {
      if (this.$route.params.categoryID != undefined) {
        return this.$route.params.categoryID
      } else {
        return 'geluidsmateriaal'
      }
    },
    gridListing: function() {
      return this.$store.getters.gridListing
    }
  },
  watch: {
    index: async function(val) {
      if (this.gettingData) {
        return
      }
      this.gettingData = true

      await this.updateItemData({ index: val })

      this.gettingData = false
    },
    sortOption: async function(val) {
      let orderBy = null
      let sortReverse = null

      if (val === 'az') {
        orderBy = 'Description'
        sortReverse = false
      }

      if (val === 'za') {
        orderBy = 'Description'
        sortReverse = true
      }

      if (val === 'price-low-high') {
        orderBy = 'WeekPrice'
        sortReverse = true
      }

      if (val === 'price-high-low') {
        orderBy = 'WeekPrice'
        sortReverse = false
      }

      this.updateItemData({ orderBy, sortReverse })
    },

    categoryID: async function() {
      this.items = []

      if (this.searchTerm && this.searchTerm.length > 1) {
        this.breadCrumbs = await generateBreadCrumbs({
          categoryID: this.currentCategory.categoryID
        })
      }
      await this.updateItemData()
    },
    searchTerm: async function(val) {
      if (this.gettingData) {
        return
      }
      this.gettingData = true

      await this.updateItemData({ search: val })

      this.gettingData = false
    }
  },
  async created() {
    if (!this.categoryID) {
      await this.$store.dispatch('categories/fetchDefaultCategory')
    }

    await this.$store.dispatch('categories/fetchCategories')

    this.breadCrumbs = await generateBreadCrumbs({
      categoryID: this.currentCategory.categoryID
    })
    await this.updateItemData()

    if (this.$store.getters.cartCache?.CartItems?.Collection?.length === 0) {
      this.$store.commit('rentalInformation/reset')
    }
  },
  methods: {
    async updateItemData({
      size = this.size,
      index = this.index,
      search = this.searchTerm,
      categoryID = this.currentCategory.CategoryID,

      sortReverse = null
    } = {}) {
      if (search != null) {
        categoryID = null
      }
      let items = await getItems({
        size,
        index,
        search,
        categoryID,

        sortReverse
      })

      this.items = items.data.Collection
      this.currentPage = items.data.Index
      this.size = items.data.Size
      this.total = items.data.Total
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    toggleGridListing() {
      this.$store.commit('toggleGridListing')
    }
  }
}
</script>

<style lang="scss" scoped></style>
