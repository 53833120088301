<template>
  <span>
    <b-button
      v-if="!icon"
      variant="secondary"
      class="mb-2 mr-2"
      @click="addToCart()"
      >In winkelmandje
      <font-awesome-icon
        v-if="loading"
        class="fa-spin"
        far
        :icon="['fad', 'spinner']"
    /></b-button>

    <b-button
      v-if="icon"
      variant="secondary"
      class="mb-2 mr-2"
      @click="addToCart()"
    >
      <font-awesome-icon v-if="!loading" fas icon="shopping-cart"/>
      <font-awesome-icon
        v-if="loading"
        class="fa-spin"
        far
        :icon="['fad', 'spinner']"
    /></b-button>
  </span>
</template>

<script>
import { getCart, addToCartSimple } from '@/services/CartService'

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    icon: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      loading: false
    }
  },

  methods: {
    addToCart: async function() {
      let compositionID = null
      let itemID = this.item.ID

      this.loading = true

      if (this.item.Type === 'Composition') {
        compositionID = itemID
        itemID = null
      }

      const result = await addToCartSimple({
        itemID,
        compositionID
      })

      if (result.status === 204 || result.status === 400) {
        let toastMessage =
          'Artikel niet toegevoegd aan winkelwagen, artikel is niet (meer) beschikbaar vooruw uitleenperiode of gekozen filiaal.'
        let serverMessage = result.data.Message.toString()
        if (serverMessage === 'InsufficientStock') {
          toastMessage = 'Artikel is niet beschikbaar voor uw uitleenperiode.'
        } else if (serverMessage === 'InvalidWarehouse') {
          toastMessage = `Dit artikel is enkel verkrijgbaar in de uitleenpost(en) ${result.data.Data.AvailableWarehouseIDs.join(
            ', '
          )}`
        }
        this.$toasted.show(toastMessage)

        this.loading = false
        return
      }

      this.$toasted.show(
        `Artikel ${this.item.Description} toegevoegd aan winkelwagen`
      )

      await getCart()

      this.loading = false
    }
  }
}
</script>

<style></style>
